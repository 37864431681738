@include keyframes(bounce) {
  0%, 25%, 75%, 100% {
    -moz-transform: translateY(0);
    transform: translateY(0);
  }
  50% {
    -moz-transform: translateY(-8px);
    transform: translateY(-8px);
  }
  // 60% {
  //   -moz-transform: translateY(-10px);
  //   transform: translateY(-10px);
  // }
}

@include keyframes(fadeHome) {
  0% {
    opacity: 0;
    -moz-transform: translate(0, 12px);
    transform: translate(0, 12px);
  }
  100% {
    -moz-transform:  translate(0, 0px);
    transform: translate(0, 0px);
    opacity: 1;
  }
}

#landing, #wrapper {
  &.light {
    background: #fff;
    svg #Group_3 {
      path {
        fill: #011533;
      }
    }
    a.bg-white {
      @extend .bg-primary;
    }
    a.sign-btn {
      color: #15181F;
    }

    .small-language-select {
      .active-option {
        color: #15181F;
      }
      svg path {
        fill: #15181F;
      }
    }
    header svg.d-sm-block g path:nth-last-child(1) {
      fill: #161820;
    }
    footer {
      background: #090720;
    }
  }
}

#landing {
  scroll-behavior: smooth;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background: #090720;

  &.header-fixed {
    header {
      position: static;
    }
  }

  header {
    transition: 200ms all ease-in;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    padding: 25px 60px;
    z-index: 10;

    &.has-shadow {
      background: -moz-linear-gradient(top,  rgba(1,17,41,0.8) 0%, rgba(48,57,109,0) 100%); /* FF3.6-15 */
      background: -webkit-linear-gradient(top,  rgba(1,17,41,0.8) 0%,rgba(48,57,109,0) 100%); /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(to bottom,  rgba(1,17,41,0.8) 0%,rgba(48,57,109,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#cc011129', endColorstr='#0030396d',GradientType=0 ); /* IE6-9 */
    }

    .btn {
      margin-left: 10px;
      &.sign-btn {
        padding-right: 0;
        font-weight: bold;
        font-size: 15px;
        &:focus, &.focus {
          box-shadow: none !important;
        }
      }
    }

    @include media-breakpoint-down(xs) {
      padding: 20px 20px;
      a.btn {
        padding: 7px 20px;
        font-size: 15px;
        margin-left: 0;
      }
    }
  }

  .first-section {
    background-image: url('../../assets/images/home.jpg');
    background-size: cover;
    background-position: center;
    min-height: 100vh;
    font-size: 14px;

    .small-language-select {
      border-left: 0;
      .active-option {
        font-size: 18px !important;
      }
    }

    .blur-box {
      padding: 40px 64px;
      height: 100vh;
      width: 744px;
      fill: rgba(2, 10, 23, 0.60);
      backdrop-filter: blur(30px);
    }

    h1 span {
      margin-top: 8px;
      display: block;
      font-size: 58px;
      font-style: normal;
      font-weight: 500;
      line-height: 80px;
      letter-spacing: 0.58px;
    }

    h1 {
      font-size: 41px;
      line-height: 52px;
      margin-bottom: 0;
    }

    h1 {
      font-weight: 400;
      -moz-animation: fadeHome 4s 1;
      -webkit-animation: fadeHome 4s 1;
      animation: fadeHome 4s 1;
    }

    &.lang-arm {
      h1 span {
        font-size: 50px;
      }
    }

    .more-icon {
      cursor: pointer;
      color:#8195B3;
      text-align: center;
      margin-top: 40px;
      img {
        height: 40px;
        width: 40px;
        -moz-animation: bounce 4s infinite;
        -webkit-animation: bounce 4s infinite;
        animation: bounce 4s infinite;
        margin-bottom: 8px;
      }
      p {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        margin-bottom: 0;
      }
    }

    @include media-breakpoint-down(sm) {
      background-image: url('../../assets/images/home_sm.jpg');
      background-size: contain;
      background-position: top;
      background-repeat: no-repeat;
      min-height: calc(100vh - 64px);

      .blur-box {
        height: calc(100vh - 64px);
        padding: 32px 32px 24px 32px;
        width: 100%;
        backdrop-filter: none;

        .middle-block {
          max-width: 624px;
          margin: 0 auto;
        }
      }

      h1 span {
        font-size: 46px;
        line-height: 60px;
      }

      h1 {
        font-size: 36px;
        line-height: 46px;
      }

      .small-language-select {
        background: #030F23;
        width: 104px;
        height: 42px;
        border-radius: 8px;
        padding: 10px;
        > div.dropdown {
          width: 100%;
        }
        .active-option {
          font-size: 14px !important;
          font-weight: 400 !important;
          width: 100%;
          display: flex !important;
          justify-content: space-between;
        }
      }
    }
    @include media-breakpoint-down(xs) {
      background-image: url('../../assets/images/home_xs.jpg');
      background-size: contain;
      background-position: top;
      background-repeat: no-repeat;

      .blur-box {
        padding: 16px 16px 24px 16px;
      }

      h1 span {
        font-size: 32px;
        line-height: 42px;
      }

      h1 {
        font-size: 23px;
        line-height: 32px;
      }

      &.lang-arm {
        h1 span {
          font-size: 28px;
        }
      }
    }
  }

  .second-section {
    padding-bottom: 50px;
    .info-part {
      padding: 50px 0 0 0;
      img {
        max-width: 1000px;
        margin-top: 30px;
      }
      h3 {
        font-size: 50px;
        margin-bottom: 30px;
      }
      p {
        font-weight: 200;
        font-size: 22px;
        color: #8195b3;
        line-height: 30px;
      }
    }

    @include media-breakpoint-down(xs) {
      padding-bottom: 40px;
      .info-part {
        padding: 35px 10px 30px 10px;
        h3 {
          font-size: 35px;
          margin-bottom: 22px;
        }
        p {
          font-size: 19px;
        }
      }
    }
  }

  .question-section {
    border-top: 1px solid #18204a;
    padding: 30px 0 50px 0;
  }

  .three-section {
    transition: 400ms all ease-in;
    padding: 85px 0;
    background: url('../../assets/images/getaccountbg.jpg');
    background-color: #0a0622;
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center;
    min-height: 400px;
    h3 {
      font-size: 30px;
      color: #ffffff;
      margin-bottom: 34px;
    }
    p {
      font-weight: 200;
      font-size: 22px;
      color: #becee6;
    }
    button {
      margin-top: 40px;
      font-size: 16px;
      font-weight: 500;
      background: #feed00;
      padding: 15px 62px;
      color: #011533;
    }
    @include media-breakpoint-down(xs) {
      background: url('../../assets/images/getaccountbg-m.jpg');
      background-size: cover;
      h3 {
        margin-bottom: 25px;
      }
      button {
        margin-top: 33px;
      }
    }
    &:hover {
      // transition: 400ms all ease-out;
      // background-position: -20px -10px;
    }
  }

  .mobile-app-ref-container ~ header {
    top: 60px
  }
}

.home-anim-area {
  position: relative;
  max-width: 850px;
  margin: 30px auto 0 auto;
  text-align: center;
  @include media-breakpoint-down(sm) {
    margin-top: 10px;
    img {
      width: 100% !important;
    }
  }
}
